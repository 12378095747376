import axios from "axios";
//import { setAlert } from './alert';
import {
	GET_USER,
	GET_USERS,
	DELETE_USERS,
	ADD_USER_ERROR,
	GET_USER_HEADER,
	AUTH_ERROR,
	CLEAR_USER,
	GET_USERS_ERROR,
	STOP_USER_ALERT,
	ADD_USER,
	EDIT_USER,
	TRANSFER_OWNERSHIP,
} from "./types";
import { env as environment } from "./environment";
import refreshAuth from "./refreshAuth";
import { sendLogs } from "../actions/logs";
//var apikey = ls.get('apikey');
//var userEmail = ls.get('email');
//var orgid = ls.get('orgid');
import ls from "localstorage-slim";
import { GlobalDebug } from "../remove-consoles";

if (!environment.consoleLog) {
	GlobalDebug(false);
}

ls.config.encrypt = true;
// const token = ls.get("token")
// axios.defaults.headers.common["Authorization"] = token;

// Add users
export const addUser = (formData) => async (dispatch) => {
	//  const newForm = {...formData, }
	const randomStr = (Math.random() + 1).toString(36).substring(7);
	const rA = ["a", "d", "f", "x", "g", "h"];
	const rB = ["A", "S", "D", "K", "F", "G"];
	const rC = ["#", "@", "$", "!"];
	const rD = ["2", "4", "5", "1", "6", "8"];
	const rS =
		rA[Math.floor(Math.random() * rA.length)] +
		rB[Math.floor(Math.random() * rB.length)] +
		rC[Math.floor(Math.random() * rC.length)] +
		rD[Math.floor(Math.random() * rD.length)];

	formData.password = randomStr + rS +"@";
	console.log("actions", formData);

	// var json = { "apikey": apikey, formData}
	dispatch({ type: GET_USER_HEADER });
	try {
		const res = await axios.post(environment.addWorker, formData);

		console.log("res header " + res);
		//console.log("res header " + JSON.stringify(res.data.message));
		sendLogs("addUser", "addUser success", "actions/users.js");
		dispatch({
			type: ADD_USER,
			//  payload: res.data
		});
		dispatch(getUsers());

		//console.log("dispatch");
		// setTimeout(function(){ dispatch(getUsers()) }, 3000);
	} catch (err) {
		//console.log("error " + err);
		//console.log("error " +  JSON.stringify(err.message));
		// //console.log("error res" + res);
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("addUser", formData, null, false));
		} else {
			sendLogs("addUser Failed", err.response, "actions/users.js");
			dispatch({
				type: ADD_USER_ERROR,
				payload: err.response,
			});
		}
	}
};

// Users List
export const getUsers = () => async (dispatch) => {
	dispatch({ type: CLEAR_USER });
	dispatch({ type: GET_USER_HEADER });
	 // const token = ls.get("token")
  // axios.defaults.headers.common["Authorization"] = token;
	var json = { emailId: ls.get("email"), apiKey: ls.get("apikey") };
	try {
		const res = await axios.post(environment.getWorkersList, json);
		//console.log("res " + res);
		//console.log("res message " + res.data.message);
		if (res.data.error === true) {
			sendLogs("getUsers Failed", res.data, "actions/users.js");
			dispatch({
				type: GET_USERS_ERROR,
				payload: res.data,
			});
		} else {
			sendLogs("getUsers", "getUsers success", "actions/users.js");
			dispatch({
				type: GET_USERS,
				payload: res.data,
			});
		}
	} catch (error) {
		if (ls.get("refreshToken") && error.message === "Request failed with status code 401") {
			dispatch(refreshAuth("getUsers"));
		} else {
			sendLogs("getUsers Failed", error.message, "actions/users.js");
			dispatch({
				type: AUTH_ERROR,
				payload: error.message,
			});
		}
	}
};

// get user
export const getUser = (email) => async (dispatch) => {
	var json = { emailId: email };
	try {
		const res = await axios.post(environment.getWorker, json);

		dispatch({
			type: GET_USER,
			payload: res.data,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("getUser", email));
		} else {
			sendLogs("getUser Failed", err.message, "actions/users.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}
};

// Edit users
export const updateUser = (formData) => async (dispatch) => {
	// var json = { "apikey": apikey, formData}
	try {
		const res = await axios.post(environment.updateWorker, formData);

		console.log("dispatch" + res);
		sendLogs("updateUser", "updateUser success", "actions/users.js");
		dispatch({
			type: EDIT_USER,
		});
		// setTimeout(function(){ dispatch(getUsers()) }, 3000);
		dispatch(getUsers());
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("updateUser", formData));
		} else {
			sendLogs("updateUser Failed", err.message, "actions/users.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}
};

// delete users
export const deleteUser = (email) => async (dispatch) => {
	var json = {
		apiKey: ls.get("apikey"),
		orgEmailId: ls.get("email"),
		workerEmailId: email,
	};
	try {
		const res = await axios.post(environment.deleteWorker, json);
		sendLogs("deleteUser", "deleteUser success", "actions/users.js");
		dispatch({
			type: DELETE_USERS,
			payload: res.data,
		});
		dispatch(getUsers());
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("deleteUser", email));
		} else {
			sendLogs("deleteUser Failed", err.message, "actions/users.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}
};

//transfer owner
export const transferOwnership = (currentOwner, newOwner) => async (dispatch) => {

	var json = {
		currentowner: currentOwner,
		newowner: newOwner
	};

	try {
		const res = await axios.post(environment.ownerTransfer, json);
		sendLogs("transferOwnership", "transferOwnership success", "actions/users.js");
		dispatch({
			type: TRANSFER_OWNERSHIP,
			payload: res.data
		});
		dispatch(getUsers());

		//clear local storage
		localStorage.clear();

		// Redirect to login page
		window.location.href = '/login';
		

	}
	catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("transferOwnership", currentOwner, newOwner));
		}
		else {
			sendLogs("deleteUser Failed", err.message, "actions/users.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}

	}
}

export const removeAlert = () => async (dispatch) => {
	dispatch({ type: STOP_USER_ALERT });
	dispatch(getUsers());
};
