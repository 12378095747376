import axios from 'axios';
import ls from 'localstorage-slim';

const setupAxiosHeaders = () => {
  // Get token from localStorage
  let token = ls.get("token");
  
  if (token) {
    // If token exists but doesn't start with Bearer, add it
    const formattedToken = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
    axios.defaults.headers.common['Authorization'] = formattedToken;
  }
};

// Axios interceptor to handle token updates
axios.interceptors.request.use(
  (config) => {
    const token = ls.get("token");
    if (token) {
      config.headers.Authorization = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default setupAxiosHeaders; 