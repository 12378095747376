import axios from "axios";
import FormData from "form-data";
import {
	//  CLEAR_ORG,
	//AUTH_ERROR,
	GET_ORG_ITEM,
	ORG_ERROR,
	UPDATE_ORG,
	GET_ORG_HEADER,
	REMOVE_BACKGROUND,
	REMOVE_BACKGROUND_ERROR,
} from "./types";
import { env as environment } from "./environment";
import refreshAuth from "./refreshAuth";
import ls from "localstorage-slim";
import { GlobalDebug } from "../remove-consoles";

if (!environment.consoleLog) {
	GlobalDebug(false);
}

ls.config.encrypt = true;
// axios.defaults.headers.common["Authorization"] = ls.get("token");

// var apikey = ls.get('apikey')
// var email = ls.get('email')

let getOrgCount = 0;
export const getOrg = () => async (dispatch) => {
	// dispatch({ type: CLEAR_ORG });
	 // const token = ls.get("token")
  // axios.defaults.headers.common["Authorization"] = token;
	dispatch({ type: GET_ORG_HEADER });
	// dispatch({ type: GET_ORG });
	var json = { apikey: ls.get("apikey") };
	try {
		const res = await axios.post(environment.orgdetails, json);
		console.log(res.data);
		if (res.data) { getOrgCount = 0; }
		dispatch({
			type: UPDATE_ORG,
			payload: res.data,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error") && getOrgCount < 5) {
			getOrgCount++;
			dispatch(refreshAuth("getOrg"));
		} else {
			getOrgCount = 0;
			dispatch({
				type: ORG_ERROR,
				payload: err.response,
			});
		}
	}
};

let orgCount = 0;
export const getOrgList = () => async (dispatch) => {
	 // const token = ls.get("token")
  // axios.defaults.headers.common["Authorization"] = token;
	const tokencheck=ls.get("token");
	console.log("inside orgn token passed or not" )
	// dispatch({ type: CLEAR_ORG });
	dispatch({ type: GET_ORG_HEADER });
	// dispatch({ type: GET_ORG });
	console.log("Inside getorglist");
	// var json = { apikey: ls.get("apikey") };
	try {
		const res = await axios.get(environment.orglist);
		// console.log("orglist::", res.data);
		// dispatch({
		// 	type: UPDATE_ORG,
		// 	payload: res.data,
		// });
		if (res.data) { orgCount = 0 }
		return res.data;
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error") && orgCount < 5) {
			orgCount++;
			dispatch(refreshAuth("getOrgList"));
		} else {
			orgCount = 0;
			console.log(err);
			dispatch({
				type: ORG_ERROR,
				payload: err.response,
			});
		}
	}




};

// --- Using node api to upload the second logo to g-cloud ---
export const uploadSecondLogo = async (file, btnId) => {
	 // const token = ls.get("token")
  // axios.defaults.headers.common["Authorization"] = token;
	if (btnId) console.log("btn-id::", btnId);
	var id = btnId ? btnId : ls.get("orgid");
	// let url = "http://127.0.0.1:8000/api/logo-upload/" + id;
	let url = "https://logo.diro.live/api/logo-upload/" + id;

	try {
		const formData = new FormData();
		formData.append("file", file);
		const config = {
			headers: {
				accept: "application/json",
				"Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
			},
		};
		const res = await axios.post(url, formData, config);
		console.log(res);
	} catch (err) {
		console.log(err);
		// dispatch({
		// 	type: ORG_ERROR,
		// 	payload: err.response,
		// });
	}
};

export const onRemBg = (formData) => async (dispatch) => {
	delete axios.defaults.headers.common["Authorization"];
	var reader = new FileReader();
	axios({
		url: environment.removebg,
		method: "post",
		data: formData,
		headers: {
			"X-Api-Key": environment.removeBgApiKey,
		},
		responseType: "blob",
		encoding: null,
	})
		.then((response) => {
			reader.readAsDataURL(response.data);
			reader.onloadend = function () {
				var img = reader.result;
				dispatch({
					type: REMOVE_BACKGROUND,
					payload: img,
				});
			};
		})
		.catch((error) => {
			console.error("Request failed:", error);
			dispatch({
				type: REMOVE_BACKGROUND_ERROR,
				payload: error.response,
			});
		});
	// axios.defaults.headers.common["Authorization"] = ls.get("token");
};

export const getbillingOrg = () => async (dispatch) => {
	// dispatch({ type: CLEAR_ORG });
	// dispatch({ type: GET_ORG_HEADER });
	// dispatch({ type: GET_ORG });
	 // const token = ls.get("token")
  // axios.defaults.headers.common["Authorization"] = token;
	var json = { apikey: ls.get("apikey"), email: ls.get("email") };
	axios
		.post(environment.orgaccount, json)
		.then((response) => {
			// console.log("res " + JSON.stringify(response.data));
			dispatch({
				type: GET_ORG_ITEM,
				payload: response.data,
			});
		})
		.catch((error) => {
			console.log(error.request);
			console.log(error.request.data);
			console.log(error.request.code);
			console.log(error.request.header);
			if (error.response) {
				if (ls.get("refreshToken") && ls.get("roles") !== "User" && error.message === "Request failed with status code 401") {
					dispatch(refreshAuth("getbillingOrg"));
				}
			}
			// else if(error.message !== "Cannot read properties of undefined (reading 'data')" || error.message !== "Cannot read properties of undefined (reading '0')")
			// {
			//   dispatch({
			//     type: AUTH_ERROR,
			//     payload: error.message
			//   });
			// }
		});
	// try {
	//     const res = await axios.post(environment.orgaccount, json);
	//     console.log("get billing1 " +res.data);
	//     console.log("get billing " + JSON.stringify(res.data) + typeof(res));
	//     dispatch({
	//         type: GET_ORG_ITEM,
	//         payload: res.data
	//     })
	//     } catch (err) {
	//       console.log("org" + err)
	//       if(ls.get('refreshToken') && ls.get('roles') !== "User" && err.message === 'Network Error')
	//       {
	//         dispatch(refreshAuth('getbillingOrg'));
	// }
	// else if(err.message !== "Cannot read properties of undefined (reading 'data')")
	// {
	//   dispatch({
	//     type: AUTH_ERROR,
	//     payload: err.message
	//   });
	// }

	//   dispatch({
	//       type: ORG_ERROR,
	//       payload: err.response
	//   })
	// }
};
let updCount = 0;
export const updateOrg = (formData) => async (dispatch) => {
	 // const token = ls.get("token")
  // axios.defaults.headers.common["Authorization"] = token;
	// var json = { "apikey": apikey, formData}
	try {
		const res = await axios.post(environment.updateorganization, formData);
		if (res.data) { updCount = 0; }
		dispatch({
			type: UPDATE_ORG,
			payload: res.data,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error") && updCount < 5) {
			updCount++;
			dispatch(refreshAuth("updateOrg", formData));
		} else {
			updCount = 0;
			dispatch({
				type: ORG_ERROR,
				payload: err.response,
			});
		}
	}
};
